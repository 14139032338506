<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <div>
        <v-tabs v-model="tab" background-color="transparent" color="info" grow>
          <v-tab class="pt-3 pb-3"
            >{{ edit_user ? "User Info" : "Create User" }}
          </v-tab>
          <v-tab v-if="edit_user" class="pt-3 pb-3"> Password </v-tab>
        </v-tabs>
      </div>
      <v-divider class="mt-0"></v-divider>
      <v-tabs-items v-if="dialog" v-model="tab">
        <v-tab-item>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="User Name*"
                    required
                    v-model="user.username"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    class="mt-4"
                    :items="roles_list"
                    label="Role*"
                    item-text="label"
                    item-value="value"
                    v-model="user.role"
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="user.role == ROLE.ASSISTANT">
                  <v-autocomplete
                    v-model="chief"
                    :items="users_list"
                    :loading="isLoadingUser"
                    :search-input.sync="searchUser"
                    @focus="() => onLoadUsers(`role=${ROLE.BUYER}`)"
                    item-text="display_name"
                    label="Chief"
                    placeholder="Search"
                    return-object
                    no-filter
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="!edit_user">
                  <v-text-field
                    label="Password*"
                    type="password"
                    required
                    v-model="password"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="!edit_user">
                  <v-text-field
                    label="Password Again*"
                    type="password"
                    required
                    v-model="pasword_again"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="is_loader"
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              :loading="is_loader"
              color="blue darken-1"
              text
              @click="onSave"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item v-if="edit_user">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Password*"
                    type="password"
                    required
                    v-model="password"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password Again*"
                    type="password"
                    required
                    v-model="pasword_again"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="is_loader"
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              :loading="is_loader"
              color="blue darken-1"
              text
              @click="changePassword"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { USER_ROLE, ROLE, requestStatus } from "@/services/services";
import { mapActions, mapState } from "vuex";
import * as BROADCASTS_TYPES from "@/store/modules/broadcasts/types";
import {
  createUser,
  editUser,
  changePassword,
} from "@/store/modules/users/api";

export default {
  name: "CreateUserDialog",
  props: {
    callback: Function,
  },
  data: () => ({
    tab: 0,
    dialog: false,
    edit_user: null,
    user: {
      username: "",
      role: "",
    },
    password: "",
    pasword_again: "",
    searchUser: "",
    isLoadingUser: false,
    is_loader: false,
    chief: null,
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.edit_user = null;
        this.user.username = "";
        this.password = "";
        this.pasword_again = "";
        this.chief = null;
        this.user.role = "";
      }
    },
    searchUser(newValue) {
      if (!this.chief || newValue !== this.chief.display_name) {
        this.onWaitChangeUser();
      }
    },
  },
  destroyed() {
    window.getApp.$off("OPEN_USER_DIALOG");
  },
  mounted() {
    window.getApp.$on("OPEN_USER_DIALOG", (user) => {
      this.dialog = true;
      if (user && user.id) {
        this.edit_user = JSON.parse(JSON.stringify(user));
        this.user.role = this.edit_user.role;
        this.user.username = this.edit_user.display_name;
        this.chief =
          this.edit_user.chief && user.role == ROLE.ASSISTANT
            ? this.edit_user.chief
            : null;
      }
    });
  },
  computed: {
    ...mapState({
      users: (state) => state.broadcasts.users,
      profile: (state) => state.auth.profile,
    }),
    USER_ROLE: () => USER_ROLE,
    ROLE: () => ROLE,
    users_list: function () {
      let results = [...this.users];
      if (this.chief) {
        results.push(this.chief);
      }
      return results;
    },
    roles_list() {
      return this.profile.role == ROLE.ADMIN
        ? this.USER_ROLE
        : this.USER_ROLE.filter(
            (role) => role.value !== ROLE.ADMIN && role.value !== ROLE.TEAMLEAD
          );
    },
  },
  methods: {
    ...mapActions({
      onLoadUsers: `broadcasts/${BROADCASTS_TYPES.LOAD_USERS}`,
    }),
    onWaitChangeUser() {
      if (
        this.searchUser &&
        (this.searchUser.length >= 2 || this.searchUser === "")
      ) {
        this.onLoadUsers(`role=${ROLE.BUYER}&search=${this.searchUser}`);
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    changePassword() {
      if (
        !this.password ||
        !this.pasword_again ||
        !this.password === this.pasword_again
      ) {
        this.$toaster.error("Passwords do not match");
        return;
      }
      this.is_loader = true;
      changePassword({
        data: {
          password1: this.password,
          password2: this.pasword_again,
        },
        id: this.edit_user.id,
      })
        .then((response) => {
          if (response.status === requestStatus.success) {
            this.$toaster.success("Saved");
            this.callback();
            this.dialog = false;
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => (this.is_loader = false));
    },
    onSave() {
      if (
        this.user.username &&
        this.user.role &&
        (this.user.role !== "assistant" || this.chief)
      ) {
        if (
          !this.edit_user &&
          (!this.password ||
            !this.pasword_again ||
            !this.password === this.pasword_again)
        ) {
          this.$toaster.error("Passwords do not match");
          return;
        }
        this.is_loader = true;
        let actions =
          this.edit_user && this.edit_user.id ? editUser : createUser;
        actions(
          this.edit_user && this.edit_user.id
            ? {
                data: {
                  ...this.user,
                  chief:
                    this.chief && this.user.role == ROLE.ASSISTANT
                      ? this.chief.id
                      : null,
                },
                id: this.edit_user.id,
              }
            : {
                ...this.user,
                password1: this.password,
                password2: this.pasword_again,
                chief:
                  this.chief && this.user.role == ROLE.ASSISTANT
                    ? this.chief.id
                    : null,
              }
        )
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("User created");
              this.callback();
              this.dialog = false;
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.is_loader = false));
      } else {
        this.$toaster.error("Fill in all fields.");
      }
    },
  },
};
</script>

<style scoped></style>
