<template>
  <div>
    <v-app-bar relative color="#1e1e2d" dark>
      <v-app-bar-nav-icon
        class="navbar-drawer"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <img style="width: 100px" src="@/assets/Logo_HollyAff.png" alt="" />
      <Clock />
      <v-spacer></v-spacer>
      <!-- <v-app-bar-title class="ml-4">{{ pageName }}</v-app-bar-title> -->
      <v-list
        color="transparent"
        dense
        class="p-0 d-flex align-items-center top-header-navbar"
        style="height: 35px; padding: 0"
      >
        <v-list-item-group
          v-model="selectedItem"
          color="grey lighten-4"
          class="d-flex"
        >
          <template v-for="(item, i) in items">
            <v-list-item
              :key="i"
              :link="true"
              :to="item.url"
              class="mb-0"
              v-if="checkPermissions(item.name)"
            >
              <!-- @click="pageName = item.title" -->
              <v-list-item-content>
                <div class="header-item-link">
                  <v-icon v-text="item.icon"></v-icon>
                  {{ item.title }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <div class="user-dropdown ml-2">
        <v-menu
          :close-on-content-click="false"
          top
          :offset-y="true"
          content-class="user-dropdown-nav"
          v-model="menuOpen"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="user-dropdown__open" text v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list>
            <div class="user-dropdown__main">
              <div class="user-info">
                <div class="user-icon">
                  <div class="user-info__img">
                    {{ profile.display_name[0].toUpperCase() }}
                  </div>
                  <div class="user-info__name">
                    {{ profile.display_name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="user-dropdown__btns">
              <v-btn depressed @click="onLogout"> Logout </v-btn>
            </div>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary color="#1e1e2d">
      <div class="d-flex align-center justify-center mt-4">
        <img style="width: 100px" src="@/assets/Logo_HollyAff.png" alt="" />
      </div>
      <v-list nav dense flat>
        <v-list-item-group active-class="active-nav-item">
          <template v-for="(item, i) in items">
            <v-list-item
              :key="i"
              :link="true"
              :to="item.url"
              class="mb-0 nav-item"
              v-if="checkPermissions(item.name)"
            >
              <template>
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Clock from "../components/ClockComponent.vue";
import { page_name, Permissions } from "../services/services";
import { LOGOUT } from "@/store/modules/auth/types";

export default {
  name: "HeaderComponent",
  props: { toggleDrawer: Function },
  components: {
    Clock,
  },
  data: () => ({
    selectedItem: 1,
    // pageName: "",
    drawer: false,
    menuOpen: false,
  }),
  // mounted() {
  //   this.changePageName();
  // },
  methods: {
    ...mapActions({
      logout: `auth/${LOGOUT}`,
    }),
    onLogout() {
      this.logout();
      window.location.href = "/login";
    },
    // changePageName() {
    //   this.pageName = [...this.items].find(
    //     (item) => item.url === this.$route.path
    //   )
    //     ? [...this.items].find((item) => item.url === this.$route.path).title
    //     : "";
    // },
    checkPermissions: function (page) {
      return !!Permissions[this.profile.role][page];
    },
  },
  // watch: {
  //   "$route.fullPath": function (newPath, oldPath) {
  //     this.changePageName();
  //   },
  // },
  computed: {
    ...mapState({
      settings: (state) => state.layout.header,
      profile: (state) => state.auth.profile,
    }),
    ...mapGetters("auth", ["profile"]),
    items: () => [
      {
        title: "Contacts",
        icon: "mdi-account-group",
        url: "/contacts",
        name: page_name.contacts,
      },
      {
        title: "Broadcasts",
        icon: "mdi-bullhorn-outline",
        url: "/broadcasts",
        name: page_name.broadcasts,
      },
      {
        title: "Domains",
        icon: "mdi-web",
        url: "/domains",
        name: page_name.domains,
      },
      {
        title: "Imported files",
        url: "/imported-files",
        icon: "mdi-file-import",
        name: page_name.imported_files,
      },
      {
        title: "Exported files",
        url: "/exported-files",
        icon: "mdi-file-export",
        name: page_name.exported_files,
      },
      {
        title: "Users",
        url: "/users",
        icon: "mdi-account-group",
        name: page_name.users,
      },
    ],
  },
};
</script>

<style scoped lang="scss">
.top-header-navbar {
  .v-list-item {
    font-size: 12px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    .header-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 17px;
        margin-right: 6px;
      }
    }
  }
  .v-list-item--link:before {
    border-radius: 8px;
  }
}
.v-list--dense .v-list-item {
  .v-list-item__icon {
    margin-right: 8px;
  }
  .v-badge {
    top: -8px;
    right: 10px;
  }
}

.nav-item {
  color: #9899ac !important;
  padding: 6px;
  i {
    color: #9899ac !important;
  }
}

.active-nav-item {
  color: white !important;
  background-color: #1b1b28 !important;
  i {
    color: white !important;
  }
}

.navbar-drawer {
  display: none;
}

@media (max-width: 991px) {
  .navbar-drawer {
    display: block;
  }
  .top-header-navbar {
    display: none !important;
  }
}

.user-dropdown {
  .v-btn__content {
    font-size: 13px;
    i {
      font-size: 20px;
    }
  }
  &__btns {
    padding: 12px;
    display: flex;
    align-items: flex-end;
  }
  &__main {
    width: 250px;
    .user-info {
      background-color: #1e1e2d;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 12px 12px;
      .user-icon {
        display: flex;
        align-items: center;
      }
      .telegram-btn {
        margin-top: 12px;
      }
      &__img {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(250, 250, 250, 0.1);
        color: white;
        font-size: 25px;
        font-weight: 500;
        border-radius: 4px;
      }
      &__name {
        font-size: 15px;
        color: white;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }
}
.user-dropdown-nav {
  margin-top: 50px;
  .v-list {
    padding: 0;
  }
}
</style>
