<template>
  <v-card class="ma-3 contact-filter pa-3">
    <div class="users-table__header">
      <div class="users-table__header-total">
        <b>Users</b>
        <div>
          <v-divider
            style="height: 20px; width: 7px; margin: 0 6px"
            inset
            vertical
          ></v-divider>
          <span style="font-size: 13px">Total</span>:
          <b>{{ users ? users.count : 0 }}</b>
          <span
            v-if="users && users.took"
            style="font-size: 12px; color: #b9b9b9"
          >
            <v-divider
              style="height: 20px; width: 7px; margin: 0 6px"
              inset
              vertical
            ></v-divider
            >took: {{ contactsData.took }}ms
          </span>
        </div>
      </div>
      <div class="filters-actions">
        <div class="filters-actions__filters d-flex">
          <div class="filter-select">
            <v-text-field
              v-model="search"
              hide-details
              label="Search"
              @input="(value) => changeFilter({ search: value })"
            ></v-text-field>
          </div>
          <div class="filter-select">
            <v-select
              :items="USER_ROLE"
              label="Role"
              item-text="label"
              item-value="value"
              hide-details
              v-model="role"
              @change="(value) => changeFilter({ role: value })"
              clearable
            ></v-select>
          </div>
        </div>
        <div class="filters-actions__actions">
          <v-btn @click="emitUserDialog" depressed small> Create User </v-btn>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div>
      <div v-if="users && users.results && users.results.length">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Chief</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users.results" :key="index">
                <td>
                  {{ user.display_name }}
                </td>
                <td>
                  {{ user.role }}
                </td>
                <td>
                  {{ user.chief && user.chief.display_name }}
                </td>
                <td>
                  <div class="d-flex justify-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          icon
                          color="primary"
                          :title="`Edit User`"
                          @click="emitUserDialog(user)"
                        >
                          <v-icon small>mdi-cog</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit User</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <CustomPagination
          :count="users.count"
          :limit="filter.limit"
          :offset="filter.offset"
          :changeOffset="
            (value) => {
              changeFilter({ offset: value });
            }
          "
        />
      </div>
      <span
        v-else-if="!is_loading"
        class="d-flex align-center justify-center text-caption"
        >No data found</span
      >
    </div>
    <UserDialog :callback="() => onGetUsers()" />
  </v-card>
</template>

<script>
import { getUsers } from "@/store/modules/users/api";
import CustomPagination from "@/components/CustomPagination.vue";
import UserDialog from "./components/UserDialog.vue";
import { USER_ROLE } from "@/services/services";
import { CHANGE_FILTER } from "@/store/modules/page/types";

import {
  // objectFromQueryString,
  // changeUrlQuery,
  requestStatus,
  buildFilterString,
} from "@/services/services";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    CustomPagination,
    UserDialog,
  },
  data() {
    return {
      is_loading: false,
      users: null,
      // filter: {
      //   limit: 30,
      //   offset: 0,
      //   role: "",
      //   search: "",
      // },
      role: "",
      search: "",
    };
  },
  computed: {
    USER_ROLE: () => USER_ROLE,
    ...mapState({
      filter: (state) => state.page.filter,
      search_field: (state) =>
        state.page.filter ? state.page.filter.search : "",
      filter_role: (state) => (state.page.filter ? state.page.filter.role : ""),
    }),
  },
  watch: {
    filter() {
      this.onGetUsers();
    },
    search_field(newValue) {
      console.log(newValue);
      this.search = newValue;
    },
    filter_role(newValue) {
      console.log(newValue);
      this.role = newValue;
    },
  },
  created() {
    this.search = this.search_field || "";
    this.role = this.filter_role || "";
    this.onGetUsers();
  },
  methods: {
    ...mapMutations({
      changeFilter: `page/${CHANGE_FILTER}`,
    }),
    onChangeSearch() {
      this.onGetUsers();
    },
    emitUserDialog(user) {
      window.getApp.$emit("OPEN_USER_DIALOG", user ? user : null);
    },
    onGetUsers() {
      // changeUrlQuery(this.filter);
      this.is_loading = true;
      getUsers(buildFilterString(this.filter))
        .then((response) => {
          if (response.status === requestStatus.success) {
            // console.log(response.response.data);
            this.users = response.response.data;
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => (this.is_loading = false));
    },
  },
};
</script>

<style lang="scss">
.users-table__header {
  display: flex;
  flex-direction: column;

  &-total {
    display: flex;
    align-items: center;
  }

  .filters-actions {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .filter-select {
      min-width: 250px;
      margin-right: 12px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}
</style>
