import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from "../store/store";
import stateAuth from "../store/modules/auth/auth"
import * as types from "../store/modules/auth/types"
import { requestStatus, Permissions, page_name } from "@/services/services";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/scenes/Login.vue'),
        meta: {},
    },
    {
        path: "/",
        redirect: "/contacts",
        component: () => import('@/scenes/AuthPage.vue'),
        children: [
            // {
            //     path: '/contacts-old',
            //     name: 'contacts-old',
            //     component: () => import('@/containers/Contacts/Contacts.vue'),
            //     meta: { requiresAuth: true },
            // },
            {
                path: '/contacts',
                name: page_name.contacts,
                component: () => import('@/containers/ElasticContacts/Contacts.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/contacts/:id',
                name: page_name.contactDetails,
                component: () => import('@/containers/Contacts/Contact.vue'),
                meta: { requiresAuth: true },
            },
            // {
            //     path: '/broadcasts-old',
            //     name: 'broadcasts-old',
            //     component: () => import('@/containers/Broadcasts/Broadcasts.vue'),
            //     meta: { requiresAuth: true },
            // },
            {
                path: '/broadcasts',
                name: page_name.broadcasts,
                component: () => import('@/containers/Broadcasts_v2/Broadcasts_v2.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/domains',
                name: page_name.domains,
                component: () => import('@/containers/Domains/Domains.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/imported-files',
                name: page_name.imported_files,
                component: () => import('@/containers/ImportedFiles/ImportedFiles.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/exported-files',
                name: page_name.exported_files,
                component: () => import('@/containers/ExportedBroadcasts/ExportedBroadcasts.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/broadcasts-groups',
                name: page_name.broadcasts_groups,
                component: () => import('@/containers/Groups/Groups.vue'),
                meta: { requiresAuth: true },
            },
            {
                path: '/users',
                name: page_name.users,
                component: () => import('@/containers/Users/Users.vue'),
                meta: { requiresAuth: true },
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const GoToNextPage = (profile: any, page: any, next: any, to: any) => {
    if (Permissions[profile.role][page]) {
        next();
    } else {
        next("/");
    }
};

router.beforeEach((to, from, next) => {
    const page = to.meta && to.meta.parent ? to.meta.parent : to.name;
    const userString = localStorage.getItem("user");
    const user = userString ? JSON.parse(userString) : null;
    // const page = to.meta && to.meta.parent ? to.meta.parent : to.name;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (user && user.token) {
            if (stateAuth.state.profile === null) {
                store
                    .dispatch(`auth/${types.GET_RPOFILE}`)
                    .then(response => {
                        if (response.status === requestStatus.success) {
                            const profile = response.response.data;
                            store.commit(`auth/${types.SET_PROFILE}`, profile);
                            GoToNextPage(profile, page, next, to);
                        } else {
                            if (response.status === requestStatus.logout || response.status === requestStatus.error_connection || response.status === requestStatus.error) {
                                store.dispatch(`auth/${types.LOGOUT}`);
                                Vue.prototype.$toaster.error("Oops, something went wrong.");
                                next({ name: "login" });
                            }
                        }
                    });
            } else {
                GoToNextPage(stateAuth.state.profile, page, next, to);
            }
        } else {
            next({ name: "login" });
        }
    } else {
        next();
    }

});

export default router
