import Http from "@/services/api.service";
import { service_url } from "@/services/config";


export function getUsers(filter:any) {
    return Http._axios({
        url: `${service_url}/api/v1/users/?${filter || ''}`,
        method: "get"
    });
}

export function createUser(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/users/create`,
        method: "post",
        data: { ...data }
    });
}
export function editUser(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/users/${data.id}`,
        method: "patch",
        data: { ...data.data }
    });
}
export function changePassword(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/users/${data.id}/change-password/`,
        method: "patch",
        data: { ...data.data }
    });
}
