export default {
    currentUser(state: any) {
        return state.user;
    },
    profile(state: any) {
        return state.profile;
    },
    isAuthenticated(state: any) {
        return state.isAuthenticated;
    }
};