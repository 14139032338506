import * as TYPES from "./types";
import {isEmptyObject} from "@/services/services";

const pageInitFilter = {
    limit: 25,
    offset: 0
};

export default {
    [TYPES.SET_FILTER](state: any, data: any) {
        state.default_filter = true;
        state.filter = isEmptyObject(data.filter)
            ? { ...pageInitFilter }
            : data.filter;
    },
    [TYPES.CHANGE_FILTER](state: any, data: any) {
        state.default_filter = false;
        const filter = {
            ...state.filter,
            offset: 0,
            ...data
        };
        if (!filter.country) {
            delete filter.country;
        }
        if (!filter.user) {
            delete filter.user;
        }
        if (!filter.role) {
            delete filter.role;
        }
        state.filter = {...filter};
    }
};
