// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const GET_RPOFILE = "getProfile";

// mutation types
export const PURGE_AUTH = "PURGE_AUTH";
export const SET_AUTH = "SET_AUTH";
export const SET_ERROR = "setError";
export const SET_PROFILE = "setProfile";