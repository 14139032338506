<template>
  <v-card>
    <div class="pa-3">
      <div>
        <b>Domains</b>
      </div>
      <v-divider></v-divider>
    </div>
    <v-card-title>
      <div class="d-flex justify-content-between w-100">
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              :items="isActiveFilter"
              label="Is active"
              :value="is_active"
              item-text="label"
              item-value="value"
              @change="(is_active) => changeFilter({ is_active })"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              :items="isBannedFilter"
              label="Is banned"
              :value="is_banned"
              item-text="label"
              item-value="value"
              @change="(is_banned) => changeFilter({ is_banned })"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" v-if="showUserFilter">
            <v-autocomplete
              v-model="user"
              :items="users_list"
              :loading="isLoadingUser"
              :search-input.sync="searchUser"
              @focus="() => onLoadUsers()"
              item-text="display_name"
              label="User"
              placeholder="Search"
              clearable
              return-object
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-btn color="primary" class="ma-2" @click="toggleOpenCreateDomain">
          Create Domain
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text :key="keyTable">
      <v-data-table
        :headers="headers"
        :items="list"
        hide-default-footer
        :loading="loading"
        :itemsPerPage="+pageFilter.limit"
        disable-sort
        dense
      >
        <template v-slot:header.name="{ header }">
          {{ header.text.toUpperCase() }}
        </template>

        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>

        <template v-slot:item.is_active="{ item }">
          <div class="d-inline-flex">
            <v-switch
              hide-details
              style="margin: 0"
              :inputValue="item.is_active"
              :loading="
                isEditing && status.editing_domain === item.id
                  ? 'warning'
                  : false
              "
              @change="(e) => onEdit(item.id, { is_active: e })"
              :disabled="isEditing"
            >
            </v-switch>
          </div>
        </template>
        <template v-slot:item.user="{ item }">
          <div class="d-flex align-items-center">
            <span v-if="item.user">{{ item.user.username }}</span>
          </div>
        </template>

        <template v-slot:item.max_links="{ item }">
          <div class="d-flex align-items-center">
            <span title="Current links">{{ item.total_links }}</span
            >/<span title="Max links">{{ item.usage_limit }}</span>
            <edit :domain="item" @onEdit="(value) => onEdit(item.id, value)" />
          </div>
        </template>

        <template v-slot:item.is_banned="{ item }">
          <span>
            <v-icon v-if="item.is_banned" color="green" small>mdi-check</v-icon>

            <v-icon v-else color="red" small>mdi-close</v-icon>
          </span>
        </template>
      </v-data-table>
      <CustomPagination
        v-if="list && list.length"
        :count="count"
        :limit="pageFilter.limit"
        :offset="pageFilter.offset"
        :changeOffset="
          (value) => {
            changeFilter({ offset: value });
          }
        "
      />
    </v-card-text>
  </v-card>
</template>

<script>
import * as BROADCASTS_TYPES from "@/store/modules/broadcasts/types";

import { mapActions, mapMutations, mapState } from "vuex";
import { LOAD_LIST, EDIT_DOMAINS } from "../../../store/modules/domains/types";
import {
  renderDate,
  requestStatus,
  buildFilterString,
  Permissions,
} from "@/services/services";
import Edit from "./Edit";
import { CHANGE_FILTER } from "../../../store/modules/page/types";
import { toBooleanValue } from "../../../services/services";
import CustomPagination from "@/components/CustomPagination.vue";
import { onLoadUserInfo } from "@/store/modules/broadcasts/api";
import _ from "lodash";

export default {
  name: "DomainsTable",
  components: { Edit, CustomPagination },
  props: { toggleOpenCreateDomain: Function },
  data: () => ({
    headers: [
      {
        text: "Domain",
        align: "start",
        value: "domain",
      },
      { text: "User", value: "user" },
      {
        text: "Created at",
        value: "created_at",
        formatter: (x) => (x ? renderDate(x) : null),
      },
      { text: "Target", value: "target" },
      { text: "Links", value: "max_links" },
      { text: "Is Active", value: "is_active" },
      { text: "Is Banned", value: "is_banned" },
    ],
    loading: false,
    isActiveFilter: [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: true,
      },
      {
        label: "Inactive",
        value: false,
      },
    ],
    isBannedFilter: [
      {
        label: "All",
        value: null,
      },
      {
        label: "Banned",
        value: true,
      },
      {
        label: "Not Banned",
        value: false,
      },
    ],
    isEditing: false,
    keyTable: "",
    isLoadingUser: false,
    searchUser: "",
    user: null,
  }),
  computed: {
    ...mapState({
      errors: (state) => state.domains.errors,
      list: (state) => state.domains.list,
      count: (state) => +state.domains.count,
      status: (state) => state.domains.status,
      pageFilter: (state) => state.page.filter,
      is_active: (state) => toBooleanValue(state.page.filter.is_active),
      is_banned: (state) => toBooleanValue(state.page.filter.is_banned),
      pageFilterUser: (state) => +state.page.filter.user || null,
      users: (state) => state.broadcasts.users,
      profile: (state) => state.auth.profile,
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    showUserFilter: function () {
      return !!Permissions[this.profile.role]["broadcasts_user_filter"];
    },
    users_list: function () {
      let results = [...this.users];
      if (this.user) {
        let default_item = results.filter((item) => +this.user.id === +item.id);
        if (!default_item.length) {
          results.push(this.user);
        }
      }
      return results;
    },
  },
  methods: {
    ...mapActions({
      onLoadList: `domains/${LOAD_LIST}`,
      onEditDomain: `domains/${EDIT_DOMAINS}`,
      onLoadUsers: `broadcasts/${BROADCASTS_TYPES.LOAD_USERS}`,
    }),
    ...mapMutations({
      changeFilter: `page/${CHANGE_FILTER}`,
    }),
    onLoadData(pageFilter) {
      this.onLoadList(`${buildFilterString(pageFilter)}&ordering=-id`);
    },

    onEdit(id, data) {
      this.onEditDomain({ id, data });
    },
    onWaitChangeUser() {
      if (
        this.searchUser &&
        (this.searchUser.length >= 2 || this.searchUser === "")
      ) {
        this.onLoadUsers(`search=${this.searchUser}`);
      }
    },
  },
  watch: {
    status(newValue) {
      this.loading = newValue.load_domains === requestStatus.loading;
      this.isEditing = newValue.edit_domains === requestStatus.loading;
      this.isLoadingUser = newValue.load_users === requestStatus.loading;

      if (
        newValue.change_field === "create_domains" &&
        newValue.create_domains === requestStatus.success
      ) {
        this.onLoadData(this.pageFilter);
        this.$toaster.success("Success create domains");
      }
      if (
        newValue.change_field === "edit_domains" &&
        newValue.edit_domains === requestStatus.success
      ) {
        this.onLoadData(this.pageFilter);
        this.$toaster.success("Success update domain");
      }
      if (
        newValue.change_field === "edit_domains" &&
        newValue.edit_domains === requestStatus.error
      ) {
        this.keyTable = `${new Date()}`;
        this.$toaster.error("Error toggle domains");
      }
    },
    searchUser(newValue) {
      if (!this.user || newValue !== this.user.display_name) {
        this.onWaitChangeUser();
      }
    },
    user(newValue) {
      this.changeFilter({ user: newValue ? newValue.id : null });
    },
    pageFilter(newValue) {
      this.onLoadData(newValue);
    },
  },
  created() {
    this.onLoadData(this.pageFilter);
    this.onWaitChangeUser = _.debounce(this.onWaitChangeUser, 500);
    // Load user info from filter
    if (this.pageFilterUser) {
      onLoadUserInfo(this.pageFilterUser).then((response) => {
        if (response?.response?.data)
          this.user = {
            id: response?.response?.data.id,
            display_name: response?.response?.data.username,
          };
      });
    }
  },
};
</script>

<style scoped></style>
